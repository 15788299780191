<template>
    <div>
        <Navbar :page="`Avalição ${ form.nome ? form.nome : '' }`" link="/treinamentos" nameLink="Gestão de Treinamentos" nameLink_2="Avaliações" link_2="/avaliacoes" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-5">
                                <label for="nome" class="block text-sm font-medium">Nome avaliação</label>
                                <input type="text" v-model="form.nome" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-12">
                                <label for="atribuirPontos" class="block text-sm font-medium">
                                    <input type="checkbox" v-model="form.atribuirPontos" class="rounded-sm" id="atribuirPontos">
                                    <span class="ml-2">
                                        Atribuir pontos as perguntas, manualmente
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr class="pb-4 mx-5"/>

                    <div class="px-5 pb-4">
                        <button type="button" @click="addPergunta" class="inline-flex justify-center py-2 px-6 mb-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                            <svg xmlns="http://www.w3.org/2000/svg" class="inline w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                            criar nova questão
                        </button>

                        <button type="button" @click="$modal.show('importarModal')" class="mx-2 inline-flex justify-center py-2 px-6 mb-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg> 

                            importar questões
                        </button>

                        <a v-if="$route.params.id && form.perguntas.length > 0" :href="`${url_api}/v1/${route}/download/${$route.params.id}?token=${$store.state.token}`" type="button" role="button" target= "_blank" rel="noreferrer" class="inline-flex justify-center py-2 px-6 mb-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg> 
                            Download perguntas 
                        </a>

                        <div v-for="(item, idx) in form.perguntas" :key="idx" class="grid grid-cols-12 gap-6 my-1 md:my-3 relative">
                            <div class="col-span-12 md:col-span-12">
                                <label for="pergunta" class="block text-sm font-medium">Pergunta {{idx + 1}}</label>
                                <textarea rows="3" type="text" placeholder="Formule a questão" v-model="item.pergunta" name="pergunta" id="pergunta" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label class="block text-sm font-medium">Tipo de resposta</label>
                                <select v-model="item.tipo" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option default value="Verdadeiro ou Falso">Verdadeiro ou Falso</option>
                                    <option value="Múltipla escolha">Múltipla escolha</option>
                                    <option value="Campo aberto">Campo aberto</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-9">
                                <div v-if="item.tipo === 'Campo aberto'">
                                    <span class="text-base font-medium text-red-700">Atenção: Para respostas em campo aberto será necessário a correção manual, informando se a questão está correta, parcialmente correta ou incorreta; por parte do examinador, após a avaliação ser concluída.</span>
                                </div>
                                <div v-if="item.tipo === 'Verdadeiro ou Falso'">
                                    <label class="block text-sm font-medium mb-2">Selecione a resposta correta: </label>
                                    <label class="inline-flex items-center">
                                        <input type="radio" class="form-radio" :name="`VerdadeiroOuFalso${idx}`" v-model="item.verdadeiroFalso" :value="true">
                                        <span class="ml-2">Verdadeiro</span>
                                    </label>
                                    <label class="inline-flex items-center ml-6">
                                        <input type="radio" class="form-radio" :name="`VerdadeiroOuFalso${idx}`" v-model="item.verdadeiroFalso" :value="false">
                                        <span class="ml-2">Falso</span>
                                    </label>
                                </div>
                                <div v-if="item.tipo === 'Múltipla escolha'">
                                    <div class="flex flex-col sm:flex-row">
                                        <div class="w-full sm:w-96">
                                            <label class="block text-sm font-medium mb-2">Opção: </label>
                                            <input v-model="item.opcao" type="text" placeholder="Nome da opção" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                        </div>
                                        <div class="ml-2">
                                            <label class="block text-sm font-medium mb-2">Adicionar: </label>
                                            <button @click="addOpcoes(item)" type="button" class="inline-flex justify-center w-44 py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                                                Adicionar
                                            </button>
                                        </div>
                                    </div>
                                    <label v-if="item.multiplasOpcoes" class="block text-sm font-medium my-2">Selecione as respostas corretas: </label>
                                    <label v-for="(op, idxopcoes) in item.multiplasOpcoes" :key="idxopcoes" class="block my-3">
                                        <input type="checkbox" class="form-check" v-model="op.resposta">
                                        <span class="ml-2 text-sm">{{ op.opcao }}</span>
                                    </label>
                                </div>
                            </div>
                            <div v-if="form.atribuirPontos" class="col-span-12 md:col-span-12">
                                <label class="block text-sm font-medium my-2">Pontos: </label>
                                <div class="flex">
                                    <button type="button" @click="changePontuacao(item,'adicionar')" class="bg-green-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>

                                    </button>
                                    <div class="mx-2">
                                        <input v-model="item.pontuacao" disabled class="w-14 h-8 text-base font-medium text-center border-2">
                                    </div>
                                    <button type="button" @click="changePontuacao(item,'diminuir')" class="bg-red-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                        </svg>

                                    </button>
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-12 my-3">
                                <hr class="border-dashed border border-gray-700" />
                                <button v-if="idx === form.perguntas.length - 1" type="button" @click="addPergunta" class="inline-flex justify-center py-2 px-6 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="inline w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                    criar nova questão
                                </button>
                                <button v-if="idx === form.perguntas.length - 1" type="button" @click="$modal.show('importarModal')" class="mx-2 inline-flex justify-center py-2 px-6 mb-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                    </svg> 

                                    importar questões
                                </button>
                                
                            </div>
                            <svg @click="remove(item, idx)" xmlns="http://www.w3.org/2000/svg" class="absolute -top-2 right-2 text-red-800 w-6 h-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <modal name="importarModal" :adaptive="true" :height="'auto'">
            <div class="px-5 py-5">
                    <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
                    <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12">
                    <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-16.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
                </div>
                <div class="col-span-12">
                    <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
                    </vue-dropzone>
                </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'avaliacoes',
            idx: 0,
            form: {
                nome: '',
                perguntas: [],
                atribuirPontos: false
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/avaliacoes/importar-csv`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
                dictRemoveFile: 'Remover'
            })
        }
    },
    methods: {
        addOpcoes(item) {
            if(!item.opcao) return this.$vToastify.error("Digite a opção");
            if(!item.multiplasOpcoes) item.multiplasOpcoes = [];
            item.multiplasOpcoes.push({ opcao: item.opcao, resposta: false }); 
            item.opcao = '';
        },
        addPergunta() {
            this.idx = this.form.perguntas.length + 1; 
            this.form.perguntas.push({ ref: this.idx, pergunta: '', tipo: 'Verdadeiro ou Falso', pontuacao: 1 });
            window.scrollTo(0, document.body.scrollHeight);
        },
        async save() {
            if(!this.form.nome) return this.$vToastify.error("Digite o nome da avaliação");

            if(!this.form.perguntas?.length) return this.$vToastify.error("Informe ao menos uma pergunta");

            if(!this.form._id) {
                const req = await this.$http.post(`/v1/${this.route}`, this.form);
                if (req.data.success) {
                    this.$vToastify.success("Salvo com sucesso!");
                    this.$router.back();
                } else {
                    this.$vToastify.error(req.data.err);
                }
                return
            }
            const reqput = await this.$http.put(`/v1/${this.route}`, { _id: this.form._id, nome : this.form.nome, perguntas: this.form.perguntas.length, atribuirPontos: this.form.atribuirPontos });
            if (reqput.data.success) {
                for(let i = 0; i < this.form.perguntas.length; i++){
                    const pergunta = this.form.perguntas[i];
                    if(!pergunta.avaliacao) pergunta.avaliacao = this.form._id;
                }
                await this.$http.put(`/v1/${this.route}/perguntas`, { perguntas: this.form.perguntas, avaliacao: this.form._id });
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(reqput.data.err);
            }
        },
        async remove(pergunta,idx){
            this.form.perguntas.splice(idx, 1); 
            idx = this.form.perguntas.length + 1;
            if(pergunta._id){
                pergunta.ativo = false;
                await this.$http.put(`/v1/${this.route}/pergunta/`, pergunta);
            }
        },
        changePontuacao(p, change) {
            if(change === 'diminuir' && p.pontuacao > 0) p.pontuacao--;
            if(change === 'adicionar') p.pontuacao++;
        },
        send(file, xhr, formData) {
            formData.append('idx', this.form.perguntas.length);
        },
        async update(file, response) {
            if(!response || !response.perguntas) return;
            for(let i = 0; i < response.perguntas.length; i++){
                const perguntas = response.perguntas[i];
                this.form.perguntas.push(perguntas);
            }
            this.$modal.hide('importarModal');
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/get-avaliacao/${id}`);
            this.form = req.data.data;
            this.form.perguntas = req.data.perguntas;
        }
    },
}
</script>
